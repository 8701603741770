import {
  GET_DETAILS_CLEAR,
  GET_DETAILS_START,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_FAILURE,
  CHANGE_DETAILS_ATTRIBUTES,
  DETAILS_NOT_AVAILABLE,
} from "../constants";

const productDetailsReducer = (
  state = { loader: false, result: {}, error: false, isAvailable: true },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DETAILS_START:
      return {
        loader: true,
        result: state.result,
        isAvailable: state.isAvailable,
        error: false,
      };
    case GET_DETAILS_SUCCESS:
      return {
        loader: false,
        result: payload.data,
        isAvailable: true,
        error: false,
      };

    case GET_DETAILS_CLEAR:
      return { loader: false, result: {}, error: false, isAvailable: true };

    case GET_DETAILS_FAILURE:
      return {
        loader: false,
        result: {},
        isAvailable: false,
        error: true,
      };
    case DETAILS_NOT_AVAILABLE:
      return {
        loader: false,
        result: state.result,
        isAvailable: false,
        error: false,
      };

    case CHANGE_DETAILS_ATTRIBUTES:
      const { attrId, itemId } = payload;
      const oldResult = { ...state.result };
      let { productAttributes } = oldResult;
      const selectedArr =
        productAttributes &&
        productAttributes.filter((item) => item.attributeId === attrId);
      selectedArr &&
        selectedArr[0].attributeItems &&
        selectedArr[0].attributeItems.forEach((attrItem) => {
          if (attrItem.id === itemId) {
            attrItem.selected = true;
          } else attrItem.selected = false;
        });

      productAttributes = [...productAttributes, ...selectedArr];

      return {
        loader: state.loader,
        result: oldResult,
        isAvailable: state.isAvailable,
        error: false,
      };

    default:
      return state;
  }
};
export default productDetailsReducer;
