import {
  STATES_FETCH_SUCCESS,
  CITIES_FETCH_SUCCESS,
  MASTER_DATA_CLEAR,
} from "../constants";

const masterDataReducer = (state = { stateList: [], cityList: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case STATES_FETCH_SUCCESS:
      return {
        stateList: payload,
        cityList: state.cityList,
      };
    case CITIES_FETCH_SUCCESS:
      return {
        stateList: state.stateList,
        cityList: payload,
      };

    case MASTER_DATA_CLEAR:
      return { stateList: [], cityList: [] };

    default:
      return state;
  }
};
export default masterDataReducer;
