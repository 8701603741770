import { combineReducers } from "redux";
import searchReducer from "./searchReducer";
import sideDrawerReducer from "./sideDrawerReducer";
import offsetReducer from "./offsetReducer";
import productDetailsReducer from "./productDetailsReducer";
import cartReducer from "./cartReducer";
import cartItemCountReducer from "./cartItemCountReducer";
import checkoutReducer from "./checkoutReducer";
import orderReducer from "./orderReducer";
import masterDataReducer from "./masterDataReducer";
import wishListReducer from "./wishListReducer";
import seoReducer from "./seoReducer";
export default combineReducers({
    searchReducer,
    sideDrawerReducer,
    offsetReducer,
    productDetailsReducer,
    cartReducer,
    cartItemCountReducer,
    checkoutReducer,
    orderReducer,
    masterDataReducer,
    wishListReducer,
    seoReducer
});