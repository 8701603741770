import {
  CLEAR_WISHLIST,
  GET_WISHLIST_FAILURE,
  GET_WISHLIST_START,
  GET_WISHLIST_SUCCESS,
  UPDATE_WISHLIST,
} from "../constants";

const wishListReducer = (
  state = { loader: false, result: [], error: false },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WISHLIST_START:
      return {
        loader: true,
        result: state.result,
        error: false,
      };

    case GET_WISHLIST_SUCCESS:
      return {
        loader: false,
        result: payload,
        error: false,
      };
    case GET_WISHLIST_FAILURE:
      return {
        loader: false,
        result: [],
        error: true,
      };
    case CLEAR_WISHLIST:
      return {
        loader: false,
        result: [],
        error: false,
      };

    case UPDATE_WISHLIST:
      return {
        loader: false,
        result: payload,
        error: false,
      };

    default:
      return state;
  }
};
export default wishListReducer;
