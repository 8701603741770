import { LOAD_MORE_LIMIT } from "../../helpers/config";
import { LOAD_MORE, LOAD_MORE_CLEAR } from "../constants";

const offsetReducer = (
  state = { limit: LOAD_MORE_LIMIT, offset: 1 },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_MORE:
      return {
        limit: LOAD_MORE_LIMIT,
        offset: payload,
      };
    case LOAD_MORE_CLEAR:
      return {
        limit: LOAD_MORE_LIMIT,
        offset: 1,
      };

    default:
      return state;
  }
};
export default offsetReducer;
