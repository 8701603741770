import {
  CHECKOUT_CLEAR,
  CHECKOUT_FAILURE,
  CHECKOUT_START,
  CHECKOUT_SUCCESS,
  CHECKOUT_ADD_ADDRESS,
  CHECKOUT_UPDATE_ADDRESS,
  CHECKOUT_EDIT_ADDRESS,
} from "../constants";

const cartReducer = (
  state = { loader: false, result: {}, error: false },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CHECKOUT_START:
      return {
        loader: true,
        result: state.result,
        error: false,
      };

    case CHECKOUT_SUCCESS:
      return {
        loader: false,
        result: payload,
        error: false,
      };
    case CHECKOUT_ADD_ADDRESS:
      const oldResult = { ...state.result };
      const oldAdBook = oldResult.addressBook || [];
      oldAdBook.push(payload);
      return {
        loader: false,
        result: oldResult,
        error: false,
      };
    case CHECKOUT_UPDATE_ADDRESS:
      const old_result = { ...state.result };
      old_result.addressBook = payload;

      return {
        loader: false,
        result: old_result,
        error: false,
      };
    case CHECKOUT_EDIT_ADDRESS:
      const old_state = { ...state.result };
      const old_addressBook = old_state.addressBook || [];
      old_addressBook.forEach((element, index) => {
        if (element.id === payload.id) {
          old_addressBook[index] = payload;
          element = payload;
        }
      });
      // const newArray = old_addressBook.filter((item) => item.id !== payload.id);
      // newArray.push(payload);
      // old_state.addressBook = newArray;

      return {
        loader: false,
        result: old_state,
        error: false,
      };
    case CHECKOUT_FAILURE:
      return {
        loader: false,
        result: {},
        error: true,
      };
    case CHECKOUT_CLEAR:
      return {
        loader: false,
        result: {},
        error: false,
      };

    default:
      return state;
  }
};
export default cartReducer;
