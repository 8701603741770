import { CHANGE_CART_ITEM_COUNT } from "../constants";

const cartItemCountReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_CART_ITEM_COUNT:
      return {
        ...state,
        [payload.productId]: payload.count,
      };

    default:
      return state;
  }
};
export default cartItemCountReducer;
