import { setLocalSession } from "./auth";
import { Routes } from "./Routes";

const App = () => {
  if (process.env.NODE_ENV !== "development") {
    console.log =
      console.error =
      console.info =
      console.debug =
      console.warn =
        function () {};
  }
  
  setLocalSession();
  return <Routes />;
};

export default App;
