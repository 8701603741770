import axios from "axios";
import { ACCESS_TOKEN, API_BASE_PATH } from "../helpers/config";
import { getLocalSession, getUserSession } from "../auth";
import { toast } from "react-toastify";

export const privateInstance = axios.create({
  baseURL: API_BASE_PATH,
});

// with auth token
privateInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserSession().authToken}`,
      accessToken: ACCESS_TOKEN,
      sessionToken: getLocalSession(),
      authToken: getUserSession().authToken,
      lang: 1,
    };
    return request;
  },
  (error) => Promise.reject(error)
);

// failure handling
privateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      toast.error("Unauthorized");
    }

    Promise.reject(error);
  }
);
