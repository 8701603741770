import {
    CLEAR_CART,
    GET_CART_ITEMS_FAILURE,
    GET_CART_ITEMS_START,
    GET_CART_ITEMS_SUCCESS,
    UPDATE_CART,
} from "../constants";

const cartReducer = (
    state = { loader: false, result: {}, error: false },
    action
) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CART_ITEMS_START:
            return {
                loader: true,
                result: state.result,
                error: false,
            };

        case GET_CART_ITEMS_SUCCESS:
            return {
                loader: false,
                result: payload,
                error: false,
            };
        case GET_CART_ITEMS_FAILURE:
            return {
                loader: false,
                result: {},
                error: true,
            };
        case CLEAR_CART:
            return {
                loader: false,
                result: {},
                error: false,
            };

        case UPDATE_CART:
            return {
                loader: false,
                result: payload,
                error: false,
            };

        default:
            return state;
    }
};
export default cartReducer;