import axios from "axios";
import { toast } from "react-toastify";
import { getLocalSession, getUserSession } from "../auth/index.js";
import { ACCESS_TOKEN, API_BASE_PATH } from "../helpers/config.js";

export const publicInstance = axios.create({
  baseURL: API_BASE_PATH,
});

// with access token
publicInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":"*",
      accessToken: ACCESS_TOKEN,
      sessionToken: getLocalSession(),
      authToken: getUserSession().authToken || null,
      lang: 1,
    };
    return request;
  },
  (error) => Promise.reject(error)
);
// failure handling
publicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      toast.error("Unauthorized");
    }

    Promise.reject(error);
  }
);
