import { ORDER_DATA_CLEAR, ORDER_DATA_UPDATE } from "../constants";

const orderReducer = (state = { data: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_DATA_UPDATE:
      return {
        data: {
          ...state.data,
          ...payload,
        },
        error: false,
      };

    case ORDER_DATA_CLEAR:
      return {
        data: {},
        error: false,
      };

    default:
      return state;
  }
};
export default orderReducer;
