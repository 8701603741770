import {
    CLEAR_SEO,
    GET_SEO_FAILURE,
    GET_SEO_START,
    GET_SEO_SUCCESS,
    UPDATE_SEO,
} from "../constants";

const seoReducer = (
    state = { loader: false, result: [], error: false },
    action
) => {

    const { type, payload } = action;

    switch (type) {
        case GET_SEO_START:
            return {
                loader: true,
                result: state.result,
                error: false,
            };

        case GET_SEO_SUCCESS:

            return {
                loader: false,
                result: payload,
                error: false,
            };
        case GET_SEO_FAILURE:
            return {
                loader: false,
                result: [],
                error: true,
            };
        case CLEAR_SEO:
            return {
                loader: false,
                result: [],
                error: false,
            };

        case UPDATE_SEO:
            return {
                loader: false,
                result: payload,
                error: false,
            };

        default:
            return state;
    }
};
export default seoReducer;